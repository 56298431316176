import * as React from "react";
import { graphql } from "gatsby";

import { FormattedMessage } from "gatsby-plugin-intl";

const NotFoundPage = ({ data, location }) => {
  return (
    <div>
      <h1>
        <FormattedMessage
          id="403_message"
          defaultMessage="403: Access Denied"
        />
      </h1>
      <h2>
        <FormattedMessage
          id="403_guidance"
          defaultMessage="Try logging in again"
        />
      </h2>
      <a className="btn btn-lg btn-primary" href="/.auth/logout">
        Login
      </a>{" "}
    </div>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
